import React, { useEffect } from 'react'

import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import { graphql, useStaticQuery } from 'gatsby'

import { renderNodeRule, StructuredText } from 'react-datocms'
import {
    isHeading,
    isParagraph,
    isList,
    isLink,
} from 'datocms-structured-text-utils'

import Layout from '../hoc/Layout'
import Seo from '../components/SEO'

import '../styles/lists.scss';
import { useMenuState } from '../context'

const PolicyPages = ({ pageContext, location }) => {
    const { pageTitle, structuredContent, seoMetaTags, metadata } = pageContext
    const { setMenuOpen } = useMenuState()
    const { background } = useStaticQuery(graphql`
        query {
            background: file(
                relativePath: { eq: "images/textures/texture-img-light.png" }
            ) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
        }
    `)

    useEffect(() => {
        setMenuOpen(false)

        return () => setMenuOpen(false)
    }, [])

    const policyBgImage = getImage(background.childImageSharp.gatsbyImageData)
    const policyBackground = convertToBgImage(policyBgImage)

    return (
        <Layout locale={pageContext.locale}>
            <Seo meta={seoMetaTags?.tags} title={metadata?.title} />
            <main>
                <BackgroundImage
                    Tag="section"
                    {...policyBackground}
                    className="stacking-context-9"
                    style={{
                        backgroundRepeat: 'repeat',
                        backgroundSize: '800px',
                    }}
                >
                    <div className="min-h-[80vh] py-28 lg:py-40 lg:grid lg:grid-cols-18 text-darks1">
                        {pageTitle ? (
                            <div className="font-display uppercase text-5xl lg:text-6xl my-6 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12">
                                <h1>{pageTitle}</h1>
                            </div>
                        ) : null}
                        <StructuredText
                            data={structuredContent}
                            customNodeRules={[
                                renderNodeRule(
                                    isHeading,
                                    ({
                                        node,
                                        adapter: { renderNode },
                                        children,
                                        key,
                                    }) => {
                                        switch (node.level) {
                                            case 1:
                                                return renderNode(
                                                    `h${node.level}`,
                                                    {
                                                        key,
                                                        className:
                                                            'font-display uppercase text-5xl my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                                    },
                                                    children
                                                )
                                            case 2:
                                                return renderNode(
                                                    `h${node.level}`,
                                                    {
                                                        key,
                                                        className:
                                                            'font-display uppercase text-4xl my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                                    },
                                                    children
                                                )
                                            case 3:
                                                return renderNode(
                                                    `h${node.level}`,
                                                    {
                                                        key,
                                                        className:
                                                            'font-display uppercase text-3xl my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                                    },
                                                    children
                                                )
                                            case 4:
                                                return renderNode(
                                                    `h${node.level}`,
                                                    {
                                                        key,
                                                        className:
                                                            'font-display uppercase text-2xl my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                                    },
                                                    children
                                                )
                                            case 5:
                                                return renderNode(
                                                    `h${node.level}`,
                                                    {
                                                        key,
                                                        className:
                                                            'font-display uppercase text-xl my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                                    },
                                                    children
                                                )
                                            default:
                                                return renderNode(
                                                    `h${node.level}`,
                                                    {
                                                        key,
                                                        className:
                                                            'font-display uppercase text-lg my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                                    },
                                                    children
                                                )
                                        }
                                    }
                                ),
                                renderNodeRule(
                                    isParagraph,
                                    ({
                                        node,
                                        adapter: { renderNode },
                                        children,
                                        key,
                                    }) => {
                                        return renderNode(
                                            `p`,
                                            {
                                                key,
                                                className:
                                                    'font-body my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                            },
                                            children
                                        )
                                    }
                                ),
                                renderNodeRule(
                                    isList,
                                    ({ node, children, key }) => {
                                        return (
                                            <div className="col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12">
                                                <ul className="custom-list">
                                                    {children}
                                                </ul>
                                            </div>
                                        )
                                    }
                                ),
                                renderNodeRule(
                                    isLink,
                                    ({
                                        node,
                                        adapter: { renderNode },
                                        children,
                                        key,
                                    }) => {
                                        return renderNode(
                                            `a`,
                                            {
                                                key,
                                                className: 'text-orange',
                                                href: node.url,
                                            },
                                            children
                                        )
                                    }
                                ),
                            ]}
                        />
                    </div>
                </BackgroundImage>
            </main>
        </Layout>
    )
}

export default PolicyPages
